import React, { useMemo } from 'react';
import NumberFormat from 'react-number-format';
import leadingZeros from 'utils/leadingZeros';
import { getTotal } from 'utils/total';

interface IProps {
  groupName:string;
  renderGroupValue:(item:any) => any;
  items:any[];
}

export default function PrintTable(props:IProps) {
  const {
    groupName,
    renderGroupValue,
    items
  } = props
  const total = useMemo(() => {
    return getTotal(items);
  }, [items])

  const money = (value:number) => (
    <NumberFormat value={value} displayType={'text'} thousandSeparator={' '} decimalScale={2} fixedDecimalScale={true} />
  );

  const renderRow = (item:any, key:any, _groupName?:string) => (
    <tr key={key}>
      <td style={{width: '100%'}}>{_groupName ? _groupName : renderGroupValue(item)}</td>
      <td align="right" style={{whiteSpace: 'nowrap'}}>
        <NumberFormat value={item.count} displayType={'text'} thousandSeparator={' '} decimalScale={0} />
      </td>
      <td align="right" style={{whiteSpace: 'nowrap'}}>
        {leadingZeros(Math.floor(Math.round(item.work_time/60)/60), 2)}:{leadingZeros(Math.round(item.work_time/60)%60, 2)}
      </td>
      <td align="right" style={{whiteSpace: 'nowrap'}}>{money(item.price)}</td>
      <td align="right" style={{whiteSpace: 'nowrap'}}>{money(item.commission_price)}</td>
      <td align="right" style={{whiteSpace: 'nowrap'}}>{money(item.commission_discount_price)}</td>
      <td align="right" style={{whiteSpace: 'nowrap'}}>{money(item.joker_price)}</td>
      <td align="right" style={{whiteSpace: 'nowrap'}}>{money(item.company_cash_price)}</td>
      <td align="right" style={{whiteSpace: 'nowrap'}}>{money(item.company_rs_price)}</td>
      <td align="right" style={{whiteSpace: 'nowrap'}}>{money(item.cash_price)}</td>
      <td align="right" style={{whiteSpace: 'nowrap'}}>{money(item.terminal_price)}</td>
      <td align="right" style={{whiteSpace: 'nowrap'}}>{money(item.card_price)}</td>
      <td align="right" style={{whiteSpace: 'nowrap'}}>{money(item.tip_price)}</td>
    </tr>
  )

  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>{groupName}</th>
            <th align="right">К-ть</th>
            <th align="right">Час на зміні</th>
            <th align="right">Всього, грн.</th>
            <th align="right">Комісія, грн.</th>
            <th align="right">Знижка за брендування, грн</th>
            <th align="right">Джокер, грн.</th>
            <th align="right">Корп. рах. кеш, грн.</th>
            <th align="right">Корп. рах. р/р, грн.</th>
            <th align="right">Готівка, грн.</th>
            <th align="right">Термінал, грн.</th>
            <th align="right">Онлайн, грн.</th>
            <th align="right">Чайові, грн.</th>
          </tr>
        </thead>
        <tbody>
          { items.map((i,v)=>renderRow(i,v)) }
        </tbody>
        <tfoot>
          {renderRow(total, 1, 'Всього')}
        </tfoot>
      </table>
    </div>
  );
}