import { Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from '@mui/material';
import React, { useMemo } from 'react';
import { getTotal } from 'utils/total';
import GroupRow from './groupRow';

interface IGroupTableProps {
  groupName:string;
  renderGroupValue:(item:any) => any;
  items:any[];
}

export default function GroupTable(props:IGroupTableProps) {
  const {
    groupName,
    renderGroupValue,
    items
  } = props
  const total = useMemo(() => {
    return getTotal(items);
  }, [items])

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>{groupName}</TableCell>
            <TableCell align="right">К-ть</TableCell>
            <TableCell align="right">Час&nbsp;на зміні</TableCell>
            <TableCell align="right">Всього, грн.</TableCell>
            <TableCell align="right">Комісія, грн.</TableCell>
            <TableCell align="right">Знижка за брендування, грн</TableCell>
            <TableCell align="right">Джокер, грн.</TableCell>
            <TableCell align="right">Корп. рах. кеш, грн.</TableCell>
            <TableCell align="right">Корп. рах. р/р, грн.</TableCell>
            <TableCell align="right">Готівка, грн.</TableCell>
            <TableCell align="right">Термінал, грн.</TableCell>
            <TableCell align="right">Онлайн, грн.</TableCell>
            <TableCell align="right">Чайові, грн.</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          { items.map((item:any, index) => (
            <GroupRow
              key={index}
              item={item}
              renderGroupValue={renderGroupValue}
            />
          ))}
        </TableBody>
        { items.length ? (
          <TableFooter>
            <GroupRow
              item={total}
              renderGroupValue={() => (<b>Всього</b>)}
            />
          </TableFooter>
        ) : null}
      </Table>
    </TableContainer>
  );
}