import React, { useState, useEffect, useCallback, useRef } from 'react';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination, Grid, Hidden, TextField, InputAdornment, IconButton, Menu, MenuItem, ListItemIcon, Typography, Stack, Box } from '@mui/material';
import requests from 'api/requests';
import { DateRangePicker, IDateRange } from 'components/daterange';
import { format } from 'date-fns';
import Row from './row';
import { PrintBox } from './styles';
import { ManageHistoryOutlined, MoreVertOutlined, PrintOutlined, PublishOutlined, SearchOutlined, CheckOutlined } from '@mui/icons-material';
import { useReactToPrint } from 'react-to-print';
import PrintTable from './printTable';
import TotalTiles from 'components/totalTiles';
import { DefinedRange } from 'components/daterange/types';
import { dataRangeItem } from 'components/daterange/defaults';
import { PaginationGridBox, TablePaperBox } from 'components/styled';

interface IOrdersDriverProps {
  listURL: string;
  identificatorID: number;
  identificatorName: string;
  dateDefinedRanges?: DefinedRange[];
  dateRange?: IDateRange;
  startTime?: Date;
  hiddenCommissionDiscount?: boolean;
}

export const dateFilters = [
  {
    title: "по часу подачі",
    field: "date",
  },
  {
    title: "по часу переходу у виконані",
    field: "getresultdatetime",
  },
]

export default function OrdersDriver(props: IOrdersDriverProps) {

  const printRef: any = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    onBeforePrint: () => setAnchorMenu(null),
    onBeforeGetContent: () => {
      return new Promise<void>((resolve, reject) => {
        requests.getAll(props.listURL, {
          ...filters(),
          ordering: 'date',
        }).then((r) => {
          setPrintItems(r.body.results);
          resolve();
        })
      });
    },
    pageStyle: 'padding: 25px'
  });
  const [anchorMenu, setAnchorMenu] = React.useState<null | HTMLElement>(null);
  const [anchorDateMenu, setAnchorDateMenu] = React.useState<null | HTMLElement>(null);
  const [dateFilterField, setDateFilterField] = React.useState(dateFilters[0].field);
  const [searchString, setSearchString] = useState('');
  const [printItems, setPrintItems] = useState([])
  const [dateRange, setDateRange] = useState<IDateRange>(props.dateRange || dataRangeItem.prev_7_days(new Date()));
  const [page, setPage] = useState({
    first_loaded: false,
    items: [],
    items_count: 0,
    page: 0,
    limit: 30,
  })
  const [total, setTotal] = useState({} as any)

  const filters = useCallback(() => {
    let new_filter: any = {};
    new_filter[props.identificatorName] = props.identificatorID;
    if (dateRange.startDate) {
      new_filter[`${dateFilterField}__gte`] = format(dateRange.startDate as Date, "yyyy-MM-dd HH:mm:00");
    }
    if (dateRange.endDate) {
      new_filter[`${dateFilterField}__lte`] = format(dateRange.endDate, "yyyy-MM-dd HH:mm:59");
    }
    if (searchString) {
      new_filter.search = searchString;
    }
    return new_filter;
  }, [props.identificatorName, props.identificatorID, dateRange, searchString, dateFilterField])

  /*
  useEffect(()=>{
    const abortController = new AbortController();

    if(!id){
      abortController.abort();
    }else{
      setData(null);
      requests.get(`/base/car-card/${id}/`, {}, abortController.signal).then((r)=>{
        const deserializer = new Deserializer();
        const instance = deserializer.deserialize(CarSerializer, r.body);
        setData(instance);
      })
    }

    return () => {
      abortController.abort();
    };
  }, [id])
  */
  const load = useCallback(() => {
    window.scrollTo(0, 0);
    const abortController = new AbortController();
    const timeout = setTimeout(() => {
      requests.get(
        props.listURL,
        {
          ...filters(),
          limit: page.limit,
          offset: page.page * page.limit,
        },
        abortController.signal,
      ).then((r) => {
        setPage(page => ({
          ...page,
          first_loaded: true,
          items: r.body.results,
          items_count: r.body.count
        }));
      }).catch(() => { })
    }, 300)
    return () => {
      clearTimeout(timeout)
      abortController.abort();
    };
  }, [props.listURL, page.page, page.limit, filters])

  const loadTotal = useCallback(() => {
    const abortController = new AbortController();
    const timeout = setTimeout(() => {
      requests.get(`${props.listURL}total/`, filters(), abortController.signal).then((r) => {
        setTotal(r.body);
      }).catch(() => { })
    }, 300)
    return () => {
      clearTimeout(timeout)
      abortController.abort();
    };
  }, [props.listURL, filters])

  useEffect(() => load(), [load]);
  useEffect(() => loadTotal(), [loadTotal]);

  const order_export = () => {
    requests.download(`${props.listURL}export/`, {
      ...filters(),
      ordering: 'date',
    })
    setAnchorMenu(null);
  }

  const renderPagination = () => (
    <TablePagination
      component="div"
      count={page.items_count}
      rowsPerPage={page.limit}
      page={page.page}
      rowsPerPageOptions={[page.limit]}
      onPageChange={(e, page_number) => {
        setPage({
          ...page,
          page: page_number
        });
      }}
    />
  )

  const renderItems = () => {
    return (
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: 1 }} />
              <TableCell>№ замовлення</TableCell>
              <TableCell>Дата</TableCell>
              <TableCell>Час</TableCell>
              <TableCell>Позивний</TableCell>
              <TableCell>Клас</TableCell>
              <TableCell align="right">Тариф, грн</TableCell>
              <TableCell align="right">Джокер, грн</TableCell>
              <TableCell align="right">Очікування, грн</TableCell>
              <TableCell align="right">Інші послуги, грн</TableCell>
              <TableCell align="right">Чайові, грн</TableCell>
              <TableCell align="right">Комісія, грн</TableCell>
              {!props.hiddenCommissionDiscount ? (
                <TableCell align="right">Знижка за брендування, грн</TableCell>
              ) : null}
              <TableCell align="right">Всього, грн</TableCell>
              <TableCell>Оплата</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {page.items.map((item: any) => (
              <Row
                key={item.id}
                item={item}
                hiddenCommissionDiscount={!!props.hiddenCommissionDiscount}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  return (
    <div>
      {page.first_loaded && (
        <div>
          <Grid container>
            <Grid item xs={12} md={8}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={5} lg={4} xl={4}>
                  <Stack direction={"row"} alignItems={"start"}>
                    <Box sx={{ flexGrow: 1 }}>
                      <DateRangePicker
                        withTime={true}
                        startTime={props.startTime}
                        dateRange={dateRange}
                        onChange={(range) => setDateRange(range)}
                        definedRanges={props.dateDefinedRanges}
                      />
                    </Box>
                    <Box>
                      <IconButton onClick={(e) => {
                        setAnchorDateMenu(e.currentTarget);
                      }}>
                        <ManageHistoryOutlined />
                      </IconButton>
                      <Menu
                        anchorEl={anchorDateMenu}
                        open={!!anchorDateMenu}
                        onClose={() => {
                          setAnchorDateMenu(null);
                        }}
                        MenuListProps={{
                          'aria-labelledby': 'basic-button',
                        }}
                      >
                        {dateFilters.map((i) => (
                          <MenuItem
                            key={i.field}
                            onClick={() => {
                              setDateFilterField(i.field);
                              setAnchorDateMenu(null);
                            }}
                          >
                            <ListItemIcon>
                              <CheckOutlined fontSize="small" color={i.field === dateFilterField ? 'success' : 'disabled'} />
                            </ListItemIcon>
                            <Typography variant="inherit">{i.title}</Typography>
                          </MenuItem>
                        ))}
                      </Menu>
                    </Box>
                  </Stack>
                </Grid>
                <Grid item xs={9} sm={5} lg={4} xl={3}>
                  <TextField
                    fullWidth
                    placeholder="Пошук"
                    value={searchString}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setSearchString(e.target.value);
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchOutlined color={'action'} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={3} sm={2}>
                  <IconButton
                    onClick={(e: React.MouseEvent<HTMLElement>) => {
                      setAnchorMenu(e.currentTarget);
                    }}
                  >
                    <MoreVertOutlined />
                  </IconButton>
                  <Menu
                    anchorEl={anchorMenu}
                    keepMounted
                    open={Boolean(anchorMenu)}
                    onClose={() => {
                      setAnchorMenu(null);
                    }}
                  >
                    <MenuItem
                      // disabled={ !page.items || !page.items.length }
                      onClick={handlePrint}
                    >
                      <ListItemIcon>
                        <PrintOutlined fontSize="small" />
                      </ListItemIcon>
                      <Typography variant="inherit">Друкувати</Typography>
                    </MenuItem>
                    <MenuItem
                      // disabled={ !page.items || !page.items.length }
                      onClick={order_export}
                    >
                      <ListItemIcon>
                        <PublishOutlined fontSize="small" />
                      </ListItemIcon>
                      <Typography variant="inherit">Експорт (Excel)</Typography>
                    </MenuItem>
                  </Menu>
                </Grid>
              </Grid>
            </Grid>
            <Hidden mdDown>
              <PaginationGridBox item sm={4}>
                {renderPagination()}
              </PaginationGridBox>
            </Hidden>
          </Grid>

          <br />
          <TotalTiles items={[
            {
              title: 'Замовлень',
              value: total.count,
              badge: null,
              number_format: false,
            },
            {
              title: 'Всього',
              value: total.price,
              badge: null,
              number_format: true,
            },
            {
              title: 'Комісія',
              value: total.commission_price,
              badge: null,
              number_format: true,
            },
            {
              title: 'Знижка',
              value: total.commission_discount_price,
              badge: null,
              number_format: true,
              hidden: !!props.hiddenCommissionDiscount,
            },
            {
              title: 'Джокер',
              value: total.joker_price,
              badge: total.joker_count,
              number_format: true,
            },
            {
              title: 'Корп. рахунок кеш',
              value: total.company_cash_price,
              badge: total.company_cash_count,
              number_format: true,
            },
            {
              title: 'Корп. рахунок р/р',
              value: total.company_rs_price,
              badge: total.company_rs_count,
              number_format: true,
            },
            {
              title: 'Готівка',
              value: total.cash_price,
              badge: total.cash_count,
              number_format: true,
            },
            {
              title: 'Термінал',
              value: total.terminal_price,
              badge: total.terminal_count,
              number_format: true,
            },
            {
              title: 'Онлайн',
              value: total.card_price,
              badge: total.card_count,
              number_format: true,
            },
            {
              title: 'Чайові',
              value: total.tip_price,
              badge: total.tip_count,
              number_format: true,
            },
          ]}
          />

          <TablePaperBox>
            {renderItems()}
          </TablePaperBox>
          {renderPagination()}
        </div>
      )}

      <div style={{ display: 'none' }}>
        <PrintBox ref={printRef}>
          <PrintTable
            items={printItems}
            hiddenCommissionDiscount={!!props.hiddenCommissionDiscount}
          />
        </PrintBox>
      </div>
    </div>
  );
}