import { cyan, grey } from "@mui/material/colors";


export const API_CONFIG = {
    api_base_url: process.env['REACT_APP_API_URL'] as string,
    api_key: process.env['REACT_APP_API_KEY'] as string,
    
    car_location_ws: process.env['REACT_APP_CAR_LOCATION_WS'] as string,
}

export const ORDER_FOLDER = {
    CARSEARCH: 17,
    INCOMING: 18,
    CHECKOUT: 19,
    ACCEPTED: 20,
    HEADING: 21,
    IN_PROGRESS: 22,
    FULFILLED: 23,
    UNFULFILLED: 24,
    REMOVED: 25,
    UNDEFINED: 26,
    EDITABLE: 27,
    UNDEFAULT: 28,
    HOT: 29,
    ONGOING: 30,
    DISPATCHER1: 31,
    DISPATCHER2: 32,
    OPERATOR: 33,
}

export const PAYMENT_TYPE = {
    CASH: "Готівка",
    CARD: "Карта",
    COMPANY_CASH: "Корп. рахунок кеш",
    COMPANY_RS: "Корп. рахунок р/р",
    TERMINAL: "Термінал",
    NA: "Не визначено",
}

export const MAP_HOST = "https://ua.map.cle.com.ua"
export const WEB_STAT_URL = process.env['REACT_APP_WEB_STAT_URL'] as string;


export const THEME_NAME = process.env['REACT_APP_THEME'] as string;

let _DEFAULT_PRIMARY_COLOR = cyan[800] as string;

if(THEME_NAME === '459'){
    _DEFAULT_PRIMARY_COLOR = grey[900] as string;
}

export const DEFAULT_PRIMARY_COLOR = _DEFAULT_PRIMARY_COLOR;