import React from 'react';
import NumberFormat from 'react-number-format';
import { TableRow, TableCell } from '@mui/material';
import leadingZeros from 'utils/leadingZeros';

interface IGroupRowProps {
  renderGroupValue:(item:any) => any;
  item:any;
}

export default function GroupRow(props:IGroupRowProps) {
  const {
    renderGroupValue,
    item
  } = props;

  const money = (value:number) => (
    <NumberFormat value={value} displayType={'text'} thousandSeparator={' '} decimalScale={2} fixedDecimalScale={true} />
  );

  return (
    <TableRow hover>
      <TableCell style={{whiteSpace: 'nowrap'}}>{renderGroupValue(item)}</TableCell>
      <TableCell align="right" style={{whiteSpace: 'nowrap'}}>
        <NumberFormat value={item.count} displayType={'text'} thousandSeparator={' '} decimalScale={0} />
      </TableCell>
      <TableCell align="right" style={{whiteSpace: 'nowrap'}}>
        {leadingZeros(Math.floor(Math.round(item.work_time/60)/60), 2)}:{leadingZeros(Math.round(item.work_time/60)%60, 2)}
      </TableCell>
      <TableCell align="right" style={{whiteSpace: 'nowrap'}}>{money(item.price)}</TableCell>
      <TableCell align="right" style={{whiteSpace: 'nowrap'}}>{money(item.commission_price)}</TableCell>
      <TableCell align="right" style={{whiteSpace: 'nowrap'}}>{money(item.commission_discount_price)}</TableCell>
      <TableCell align="right" style={{whiteSpace: 'nowrap'}}>{money(item.joker_price)}</TableCell>
      <TableCell align="right" style={{whiteSpace: 'nowrap'}}>{money(item.company_cash_price)}</TableCell>
      <TableCell align="right" style={{whiteSpace: 'nowrap'}}>{money(item.company_rs_price)}</TableCell>
      <TableCell align="right" style={{whiteSpace: 'nowrap'}}>{money(item.cash_price)}</TableCell>
      <TableCell align="right" style={{whiteSpace: 'nowrap'}}>{money(item.terminal_price)}</TableCell>
      <TableCell align="right" style={{whiteSpace: 'nowrap'}}>{money(item.card_price)}</TableCell>
      <TableCell align="right" style={{whiteSpace: 'nowrap'}}>{money(item.tip_price)}</TableCell>
    </TableRow>
  );
}